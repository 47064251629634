import React, { Component } from 'react';
import '../scss/_Reviews.scss';

class Reviews extends Component {
  render() {
    return (
      <section id="reviews" className="reviews">
        <header className="header reviews-header">
          <h1 className="heading">Learn what clients say about us</h1>
        </header>
        <div className="container reviews-container">
         <a href="https://www.checkatrade.com/PurcellElectrical/Reviews.aspx" target="_blank" rel="noopener noreferrer" className="reviews-card">
          <h2 className="review-title">Installing an extractor fan</h2>
          <p className="review">Best electrician by far! If I could give 11 out of 10 I would. Anthony's work is exceptionally impressive. His a guy who knows his stuff without a benefit of doubt. Super friendly, great sense of humour and communication is outstanding; answers any questions, and keeps you well informed of what's happening and what needs to be done. Clean, tidy, efficient and professional. His tradesmen skills are rare this day and age, to find someone as reliable as him is almost impossible. If there's any other electrical jobs, without a doubt I will contact him. Highly recommended!</p>
          <p className="review-date">8 April 2019</p>
         </a>
         <a href="https://www.checkatrade.com/PurcellElectrical/Reviews.aspx" target="_blank" rel="noopener noreferrer" className="reviews-card">
          <h2 className="review-title">New heating system in classrooms/ complete rewire of electrics in classrooms/ new cabling to support new system</h2>
          <p className="review">Anthony did an amazing job. He kept me completely informed as the project developed, went above and beyond to give the jobs a fantastic finish, and left the site significantly cleaner than he found it! I highly recommend Anthony and will be using him for all of our electrical requirements in the future.</p>
          <p className="review-date">10 February 2019</p>
         </a>
         <a href="https://www.checkatrade.com/PurcellElectrical/Reviews.aspx" target="_blank" rel="noopener noreferrer" className="reviews-card">
          <h2 className="review-title">Fitted new fusebox and electric shower unit. Issued test certificate</h2>
          <p className="review">Very friendly and excellent, professional jobs done.</p>
          <p className="review-date">11 December 2018</p>
         </a>
        </div>
        <a href="https://www.checkatrade.com/PurcellElectrical/Reviews.aspx" target="_blank" rel="noopener noreferrer" role="button" className="btn-primary btn-more">Read more testimonials<i className="fas fa-angle-double-right"></i></a>
      </section>
    );
  }
}

export default Reviews;