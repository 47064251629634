import React, { Component } from 'react';
import '../scss/_Contact.scss';
import ContactForm from './ContactForm';
import Map from './Map';

class Contact extends Component {
 
  render = () => {
    return (
      <section id="contact" className="contact">
        <header className="header contact-header">
          <h1 className="heading">Contact us</h1>
        </header>

        <div className="contact-container">
          <div className="contact-form-container">
            <ContactForm />
          </div>

          <div className="contact-details">
            <p>Give us a call, book a call-back at a time of your convenience or leave your details below.  We’ll get right back to you.</p>
            <hr className="contact-line-break" />
            <h3>Purcell Electrical Ltd.</h3>
            <p><i className="fas fa-phone contact-phone"></i>+44 754 793 8119</p>
            <p><i className="fas fa-at contact-email"></i>purcellelectrical@hotmail.co.uk</p>
          </div>
        </div>

        {navigator.onLine &&
					<Map />
        }

        <div className="coverage">
          <h3 className="heading">Our coverage Area</h3>
          <ul className="coverage-list">
            <li>Woolwich</li>
            <li>Plumstead</li>
            <li>Abbey Wood</li>
            <li>Thamesmead</li>
            <li>Greenwich</li>
            <li>Deptford</li>
            <li>Lewisham</li>
            <li>South East London</li>
            <li>Kent</li>
          </ul>
        </div>
        
      </section>
    );
  }
}

export default Contact;