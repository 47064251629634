import React, { Component } from "react";
import "../scss/_Header.scss";
import LogoSmall from "../img/logo-small.png";
import Nav from "./Nav";
import Close from '../img/close-icon.svg';

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
    this.handleclick = this.handleclick.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  handleclick() {
    this.setState({ open: true });
  }

  closeDialog = () => {
    this.setState({ open: false });
  }

  render() {

    let { open } = this.state;

    return (
      <React.Fragment>
        <header className="main-header">
          <div className="main-heading">
            <img src={LogoSmall} alt="Logo Purcell Electrical" />
            <h1>
              <a href="/">Purcell Electrical</a>
            </h1>
            <h2>Electrical Services &amp; Building Enterprises</h2>
          </div>
          <div
            id="hamburger-icon"
            className="hamburger-icon"
            onClick={this.handleclick}
          />
        </header>
        {!open &&
          <div id="main-nav" className="dialog-nav close"  onClick={this.closeDialog}>
            <img src={Close} alt="Close navigation bar" className="close-icon" onClick={this.closeDialog} />
            <Nav />
          </div>
        }
        {open &&
          <div id="main-nav" className="dialog-nav open"  onClick={this.closeDialog}>
            <img src={Close} alt="Close navigation bar" className="close-icon" onClick={this.closeDialog} />
            <Nav />
          </div>
        }

        <div className="hero-image" id="background"></div>
        <div className="hero-text">
          <h2>Lorem ipsum dolor sit amet</h2>
          <a href="#contact" role="button" className="btn-primary">Contact Us <i className="fas fa-paper-plane"></i></a>
        </div>
      </React.Fragment>
    );
  }
}

export default Header;
