import React, { Component } from "react";
import "../scss/_ContactForm.scss";

class ContactForm extends Component {
  render = () => {
    return (
      <form className="contact-form">
        <label htmlFor="name">Name*</label>
        <input
          type="text"
          name="name"
          id="name"
          placeholder="Your name"
          required
        />
        <label htmlFor="surname">Last name</label>
        <input
          type="text"
          name="surname"
          id="surname"
          placeholder="Your last name"
        />
        <label htmlFor="email">E-mail*</label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="example@example.com"
          required
        />
        <label htmlFor="telephone">Telephone*</label>
        <input
          type="tel"
          name="telephone"
          id="telephone"
          placeholder="123456789"
          required
        />
        <label htmlFor="message">Message*</label>
        <textarea
          name="message"
          id="message"
          rows="5"
          placeholder="Write your message"
          required
        />
        <div className="row">
          <input type="checkbox" name="consent" id="consent" className="consent-checkbox" />
          <label htmlFor="consent">I hereby consent to  my personal data being processed by Purcell Electrical Ltd. for the purpose of communication between me and company.*</label>
        </div>
        <button type="submit" className="btn-primary btn-submit">
          Send message<i className="fas fa-paper-plane"></i>
        </button>
      </form>
    );
  };
}

export default ContactForm;