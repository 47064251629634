import React, { Component } from "react";
import '../scss/_Nav.scss';

class Nav extends Component {
  
  render = () => {

    return (
        <nav className="main-nav">
          <a href="#root">Home</a>
          <a href="#about">About us</a>
          <a href="#services">Services</a>
          <a href="#features">Features</a>
          <a href="#reviews">Reviews</a>
          <a href="#contact">contact us</a>
        </nav>      
    );
  }
}
export default Nav;