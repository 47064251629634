import React, { Component } from 'react';
import '../scss/_About.scss';
import Logo from '../img/logo.jpg';

class About extends Component {
  render() {
    return (
      <section id="about" className="about">
        <header className="header about-header">
          <h1 className="heading">Who we are</h1>
        </header>
        <div className="container about-container">
          <img src={Logo} alt="Logo"/>
          <div className="content">
            <p>Purcell Electrical aims to deliver a worry-free process with every job. Each job no matter the size is treated with the same values.</p>
            <p>For larger jobs a documented process is implemented to ensure complete transparency and understanding for all parties concerned. These documents include letter-headed quote, scope of works, job "Sign Off" form and customer complaints record</p>
          </div>
        </div>
        <a href="#contact" role="button" className="btn-primary call-now">Call now for free quotation<i className="fas fa-phone"></i></a>
      </section>
    );
  }
}

export default About;