import React, { Component } from 'react';
import '../scss/_Services.scss';

class Services extends Component {
  render() {
    return (
      <section id="services" className="services">
        <header className="header services-header">
          <h1 className="heading">What we do</h1>
        </header>
        <div className="container services-container">
          <div className="service-card">
            <i className="far fa-lightbulb"></i>
            <h2>Lighting</h2>
            <ul className="service-list">
              <li>Emergency Lighting</li>
              <li>Energy Efficient Lighting</li>
              <li>LED Lighting</li>
              <li>Garden Lighting</li>
              <li>Lighting Design</li>
            </ul>
          </div>
          <div className="service-card">
            <i className="fas fa-network-wired"></i>
            <h2>Wiring</h2>
            <ul className="service-list">
              <li>Electric Gate Wiring</li>
              <li>Rewires</li>
            </ul>
          </div>
          <div className="service-card">
            <i className="fas fa-thermometer-three-quarters"></i>
            <h2>Heating</h2>
            <ul className="service-list">
              <li>Electric Boiler Repairs</li>
              <li>Electric Heating</li>
              <li>Electric Radiators</li>
              <li>Electric Smart Thermostat</li>
              <li>Electrical Heater Repair</li>
              <li>Storage Heating</li>
              <li>Immersion Heater Installation/Repair</li>
            </ul>
          </div>
          <div className="service-card">
            <i className="far fa-circle"></i>
            <h2>Kitchen</h2>
            <ul className="service-list">
              <li>Electric Cookers</li>
              <li>Electric Stoves</li>
            </ul>
          </div>
          <div className="service-card">
            <i className="fas fa-network-wired"></i>
            <h2>Bathroom</h2>
            <ul className="service-list">
              <li>Electric Showers</li>
              <li>Hot Tub Wiring</li>
            </ul>
          </div>
          <div className="service-card">
            <i className="fas fa-tools"></i>
            <h2>Other Services</h2>
            <ul className="service-list">
              <li>Electric Vehicle Charging</li>
              <li>Electrical Contractors</li>
              <li>Electrical Installation Condition Report (Periodic Testing)</li>
              <li>Fault Finding</li>
              <li>Landlord Reports/Safety Checks</li>
              <li>Smoke Alarms</li>
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

export default Services;