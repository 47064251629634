import React, { Component } from 'react';
import '../scss/_Features.scss';

class Features extends Component {
  render() {
    return (
      <section id="features" className="features">
        <header className="header features-header">
          <h1 className="heading">Why you should choose us</h1>
        </header>
        <div className="container features-container">
          <div className="features-card circle">
            <i className="fas fa-dollar-sign"></i>
            <h3>Fair &amp; Honest Pricing</h3>
          </div>
          <div className="features-card circle">
            <i className="fas fa-dollar-sign"></i>
            <h3>Highest Professional Standards</h3>
          </div>
          <div className="features-card circle">
            <i className="fas fa-briefcase"></i>
            <h3>Clean &amp; Quality Workmanship</h3>
          </div>
          <div className="features-card circle">
            <i className="fas fa-comments"></i>
            <h3>Good Continual Communication</h3>
          </div>
        </div>
      </section>
    );
  }
}

export default Features;